import React, {useCallback, useState} from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar, ListItemIcon, ListItemText
} from "@mui/material";
import {DeleteOutline, InfoOutlined} from "@material-ui/icons";
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteLocal from "../features/deleteLocal";
import PeopleIcon from '@mui/icons-material/People';
import ListItemButton from "@mui/material/ListItemButton";
import RestoreIcon from '@mui/icons-material/Restore';
import ScaleIcon from '@mui/icons-material/Scale';
import {useIndexedDB} from "react-indexed-db";
import { useNavigate } from 'react-router-dom';
import gql from "graphql-tag";
import {useMutation} from "urql";

const deleteOnlineShotMutation = gql`
  mutation RemoveShot($hashID: ID!) {
   removeShot(hashID: $hashID)
  }
`

const Layout = ({ smGame, gameID, shots, setShots, homeName, visitorName, children, clearDatabase, setShowInfo, showInfo, setMenuDialogOpen, setTeamMenuDialogOpen }) => {
  const [deleteLocalDialogOpen, setDeleteLocalDialogOpen] = useState(false);
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const { getAll, deleteRecord: deleteShot } = useIndexedDB('shots');

  const [, deleteOnlineShot] = useMutation(deleteOnlineShotMutation)

  const shotStatusTitle = useCallback((status) => {
    switch (status) {
      case 'goal': return 'Tor';
      case 'onGoal': return 'Aufs Tor';
      case 'missed': return 'Daneben';
      case 'blocked': return 'Block';
      default: return status
    }
  }, [])

  const navigate = useNavigate();

  return (
    <Box
      width={'100%'}
      // height={'100%'}
      paddingTop={2}
      sx={{background: '#222'}}
    >
      {children}
      {deleteLocalDialogOpen && (
        <DeleteLocal clearDatabase={clearDatabase} closeAction={() => setDeleteLocalDialogOpen(false)} />
      )}
      <BottomNavigation value={''}>
        <BottomNavigationAction onClick={() => navigate('/ballbesitz')} label={'Ballbesitz'} value={'bb'} icon={<ScaleIcon />} />
        <BottomNavigationAction disabled={!gameID} onClick={() => setTeamMenuDialogOpen(prevState => !prevState)} label={'team'} value={'team'} icon={<PeopleIcon />} />
        <BottomNavigationAction onClick={() => setMenuDialogOpen(prevState => !prevState)} label={'menu'} value={'menu'} icon={<MenuIcon />} />
        <BottomNavigationAction onClick={() => setShowInfo(prev => !prev)} label="info" value="info" icon={showInfo ? <InfoIcon /> : <InfoOutlined />} />
        <BottomNavigationAction onClick={() => setDeleteLocalDialogOpen(true)} label="Folder" value="folder" icon={<DeleteOutline />} />
        <BottomNavigationAction onClick={() => setEditMenuOpen(prevState => !prevState)} label={'menu'} value={'menu'} icon={<RestoreIcon />} />
      </BottomNavigation>
      <Drawer
        anchor={"right"}
        open={editMenuOpen}
        onClose={() => setEditMenuOpen(false)}
      >
        <Box sx={{width: 400}}>
          <List>
            {shots.filter(item => item.gameID === gameID).map(shot => (
              <React.Fragment key={shot.id}>

                <ListItem>
                  <Box sx={{width: 100, paddingTop: 2, paddingBottom: 2}}>
                    {shotStatusTitle(shot.shotStatus)}
                  </Box>

                  <ListItemText
                    sx={{flexGrow: 1}}
                    primary={shot.team === 'home' ? homeName : visitorName}
                    secondary={shot.playerNo ? `Nr. ${shot.playerNo}` : ''}
                  />
                  <ListItemButton sx={{flexGrow: 0}} onClick={() => {
                    deleteShot(shot.id).then(res => {
                      // todo delete online
                      console.log('afsd', shot)
                      if (smGame) {
                        deleteOnlineShot({
                          hashID: shot.hashID
                        }).then(r => {
                          console.log(r)
                        })
                      }

                      getAll().then(dbShots => {
                        setShots(dbShots);
                      });
                    })
                  }}>
                    <DeleteOutline />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>

      </Drawer>
    </Box>
  );
};

Layout.propTypes = {}

export default Layout;
