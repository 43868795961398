import React from 'react';
import {Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import {DeleteOutline} from "@material-ui/icons";

const Player = ({player, updatePlayer, updatePlayerList, removePlayer, shots}) => {

  const totalTeamShots = shots.length
  const totalShots = shots.filter(shot => parseInt(player.number, 10) === parseInt(shot.playerNo, 10)).length
  const onGoal = shots.filter(shot => parseInt(player.number, 10) === parseInt(shot.playerNo, 10) && ['goal', 'onGoal'].includes(shot.shotStatus)).length
  const goals = shots.filter(shot => parseInt(player.number, 10) === parseInt(shot.playerNo, 10) && ['goal'].includes(shot.shotStatus)).length
  const blocked = shots.filter(shot => parseInt(player.number, 10) === parseInt(shot.playerNo, 10) && ['blocked'].includes(shot.shotStatus)).length
  const missed = shots.filter(shot => parseInt(player.number, 10) === parseInt(shot.playerNo, 10) && ['missed'].includes(shot.shotStatus)).length

  return (
    <>
      <Grid item xs={12}>
        <Paper sx={{flexGrow: 1}} elevation={6}>
          <Grid container spacing={0}>

            <Grid item xs={11}>
              <TextField
                fullWidth
                variant={"filled"}
                value={player.number}
                onChange={e => updatePlayer({...player, number: e.target.value}).then(updatePlayerList)}
                label={player.name}
                type={"number"}
                inputProps={{ inputMode: 'numeric' }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton size={"large"} onClick={() => removePlayer(player.id)}>
                <DeleteOutline />
              </IconButton>
            </Grid>

            <Grid container item xs={12} spacing={0} marginTop={1} marginBottom={1}>
              <Grid item xs={4} paddingLeft={1}>
                <Typography variant={"subtitle2"}>Gesamt:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant={"subtitle2"}>{`${totalShots} (${Math.round(totalShots / totalTeamShots * 1000) / 10 || 0}% / Team)`}</Typography>
              </Grid>

              <Grid item xs={4} paddingLeft={1}>
                <Typography variant={"subtitle2"}>Aufs Tor</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant={"subtitle2"}>{`${onGoal} (${Math.round(onGoal / totalShots * 1000) / 10 || 0}%)`}</Typography>
              </Grid>

              <Grid item xs={4} paddingLeft={1}>
                <Typography variant={"subtitle2"}>Tore</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant={"subtitle2"}>{`${goals} (${Math.round(goals / totalShots * 1000) / 10 || 0}%)`}</Typography>
              </Grid>

              <Grid item xs={4} paddingLeft={1}>
                <Typography variant={"subtitle2"}>Geblockt</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant={"subtitle2"}>{`${blocked} (${Math.round(blocked / totalShots * 1000) / 10 || 0}%)`}</Typography>
              </Grid>

              <Grid item xs={4} paddingLeft={1}>
                {/*<Typography variant={"subtitle2"}>Vorbei</Typography>*/}
              </Grid>
              <Grid item xs={2}>
                {/*<Typography variant={"subtitle2"}>{`${missed} (${Math.round(missed / totalShots * 1000) / 10 || 0}%)`}</Typography>*/}
              </Grid>

              <Grid item xs={4} paddingLeft={1}>
                <Typography variant={"subtitle2"}>Vorbei</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant={"subtitle2"}>{`${missed} (${Math.round(missed / totalShots * 1000) / 10 || 0}%)`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

Player.propTypes = {}

export default Player;
