import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, Grid, IconButton, Paper, Tab, TextField} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {useIndexedDB} from "react-indexed-db";
import BootstrapDialogTitle from "../../components/BootstrapDialogTitle";
import Tabs from "@material-ui/core/Tabs";
import {DeleteOutline} from "@material-ui/icons";
import Player from "./Player";

const TeamMenu = ({open, close, gameID, shots}) => {
  const { getByID: getGameByID } = useIndexedDB('games');
  const { getAll, getByID: getPlayer, update: updatePlayer, add, deleteRecord } = useIndexedDB('player');
  const [ visitorTeamSelected, setVisitorTeamSelected ] = useState(0)
  const [ homeTeamName, setHomeTeamName ] = useState('')
  const [ visitorTeamName, setVisitorTeamName ] = useState('')
  const [ newPlayerName, setNewPlayerName ] = useState('')
  const [ newPlayerNumber, setNewPlayerNumber ] = useState(0)
  const [ playerList, setPlayerList ] = useState([])

  const addPlayer = () => {
    add({
      team: visitorTeamSelected ? 'visitor' : 'home',
      gameID: gameID,
      name: newPlayerName,
      number: newPlayerNumber,
    }).then(() => {
      setNewPlayerName('')
      setNewPlayerNumber('')
    })
  }

  const removePlayer = id => {
    deleteRecord(id).then(updatePlayerList)
  }

  useEffect(() => {
    getGameByID(gameID).then(game => {
      setHomeTeamName(game?.home || '')
      setVisitorTeamName(game?.visitor || '')
    })
  }, [open])

  const updatePlayerList = () => getAll().then(items => {
    const filteredItems = items.filter(item => item.gameID === gameID && item.team === (visitorTeamSelected ? 'visitor' : 'home' ))
    if (JSON.stringify(playerList) !== JSON.stringify(filteredItems)) {
      setPlayerList(filteredItems)
    }
  });

  useEffect(() => updatePlayerList(), [open, addPlayer])

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} onClose={close}>
      <BootstrapDialogTitle onClose={close}>Spieler:innen</BootstrapDialogTitle>
      <DialogContent>
        <Paper >
          <Tabs
            centered
            value={visitorTeamSelected}
            onChange={(_, value) => setVisitorTeamSelected(value)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value={0} sx={{flexGrow: 1}} label={homeTeamName} />
            <Tab value={1} sx={{flexGrow: 1}} label={visitorTeamName} />
          </Tabs>
        </Paper>
        <br/>
        <Grid container spacing={2}>
          {playerList.map(player => (
            <Player
              key={player.id}
              player={player}
              removePlayer={removePlayer}
              updatePlayer={updatePlayer}
              updatePlayerList={updatePlayerList}
              shots={shots.filter(shot => shot.team === (visitorTeamSelected ? 'visitor': 'home'))}
            />
          ))}

          <Grid item xs={8}>
            <TextField fullWidth value={newPlayerName} onChange={e => setNewPlayerName(e.target.value)} label={'Spieler:in Name'} />
          </Grid>
          <Grid item xs={3}>
            <TextField fullWidth value={newPlayerNumber} onChange={e => setNewPlayerNumber(e.target.value)} label={'Spieler:in Nummer'} />
          </Grid>
          <Grid item xs={1}>
            <IconButton size={"large"} onClick={addPlayer}>
              <CheckIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {/*<Button fullWidth variant={"contained"} onClick={saveGame}>Speichern</Button>*/}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

TeamMenu.propTypes = {}

export default TeamMenu;
