import React from 'react';
import {Box, Grid, Typography} from "@mui/material";

const Sections = ({reverse, showInfo, shots}) => {
  const isInRange = (shot, sector) => {
    switch (sector) {
      case 'A':
        return shot.x >= 0 * 100 / 2 && shot.x < 1 * 100 / 2
          && shot.y >= 0 * 100 / 3 && shot.y < 1 * 100 / 3
      case 'B':
        return shot.x >= 0 * 100 / 2 && shot.x < 1 * 100 / 2
          && shot.y >= 1 * 100 / 3 && shot.y < 2 * 100 / 3
      case 'C':
        return shot.x >= 0 * 100 / 2 && shot.x < 1 * 100 / 2
          && shot.y >= 2 * 100 / 3 && shot.y < 3 * 100 / 3
      case 'D':
        return shot.x >= 1 * 100 / 2 && shot.x < 2 * 100 / 2
          && shot.y >= 0 * 100 / 3 && shot.y < 1 * 100 / 3
      case 'E':
        return shot.x >= 1 * 100 / 2 && shot.x < 2 * 100 / 2
          && shot.y >= 1 * 100 / 3 && shot.y < 2 * 100 / 3
      case 'F':
        return shot.x >= 1 * 100 / 2 && shot.x < 2 * 100 / 2
          && shot.y >= 2 * 100 / 3 && shot.y < 3 * 100 / 3
    }
  }

  const getTop = sector => {
    switch (sector) {
      case 'A':
        return `calc(100% * 0 / 3)`
      case 'B':
        return `calc(100% * 1 / 3)`
      case 'C':
        return `calc(100% * 2 / 3)`
      case 'D':
        return `calc(100% * 0 / 3)`
      case 'E':
        return `calc(100% * 1 / 3)`
      case 'F':
        return `calc(100% * 2 / 3)`
    }
  }

  const getLeft = sector => {
    switch (sector) {
      case 'A':
      case 'B':
      case 'C':
        return `calc(100% * 0 / 2)`
      case 'D':
      case 'E':
      case 'F':
        return `calc(100% * 1 / 2)`
    }
  }

  return (
    <>
      {['A', 'B', 'C', 'D', 'E', 'F'].map(sector => {
        const totalSector = shots.filter(shot => isInRange(shot, sector)).length
        const onGoal = shots.filter(shot =>
          isInRange(shot, sector) && ['goal', 'onGoal'].includes(shot.shotStatus)
        ).length
        const totalGoals = shots.filter(shot =>
          ['goal'].includes(shot.shotStatus)
        ).length
        const goals = shots.filter(shot =>
          isInRange(shot, sector) && ['goal'].includes(shot.shotStatus)
        ).length
        const blocked = shots.filter(shot =>
          isInRange(shot, sector) && ['blocked'].includes(shot.shotStatus)
        ).length

        return (
          <Box
            key={sector}
            position={"absolute"}
            top={getTop(sector)}
            left={getLeft(sector)}
            padding={1}
            width={'50%'}
            height={'calc(100% * 1 / 3)'}
            sx={reverse && {transform: 'rotate(180deg)'}}
            textAlign={!showInfo && !reverse ? 'right' : 'left'}
          >
            <Typography>{sector}</Typography>
            {showInfo && (
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant={"body2"}>Gesamt: {totalSector}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography component={'span'} fontSize={11}> {`(${Math.round(totalSector / shots.length * 100) || 0}%)`}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant={"body2"}>Aufs Tor: {onGoal}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography component={'span'} fontSize={11}> {`(${Math.round(onGoal / totalSector * 100) || 0}% / Sektor)`}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant={"body2"}>Tore: {goals}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography component={'span'} fontSize={11}> {`(${Math.round(goals / totalGoals * 100) || 0}% / Tore)`}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant={"body2"}>Geblockt: {blocked}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography component={'span'} fontSize={11}> {`(${Math.round(blocked / totalSector * 100) || 0}% / Sektor)`}</Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        )
      })}
    </>
  );
};

Sections.propTypes = {}

export default Sections;
