import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {useIndexedDB} from "react-indexed-db";
import gql from "graphql-tag";
import {useMutation} from "urql";
import moment from "moment";

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

const addOnlinePossessionMutation = gql`
  mutation CreatePossession($input: CreatePossessionInput!) {
   createPossession(input: $input) {
     hashID
   }
  }
`

const Possession = ({}) => {
  const [currentPossession, setCurrentPossession] = useState('')
  const [period, setPeriod] = useState(1)
  const [time, setTime] = useState('')
  const timeRef = useRef('')
  const [smGame, setSmGame] = useState('')
  const { getByID: getConfig } = useIndexedDB('config');
  const { getByID: getGame } = useIndexedDB('games');
  const [state, addPossession] = useMutation(addOnlinePossessionMutation)

  const updateData = data => {
    const localTime = `${pad(data.minutes, 2)}:${pad(data.seconds, 2)}`
    if (time !== localTime) {
      setTime(localTime)
      timeRef.current = localTime
    }
  }

  const connect = () => {
    const ws = new WebSocket('wss://gameboard.schokoriedel.de/');

    let listener = null

    ws.addEventListener('open', () => {
      console.log('connected');
    });

    ws.addEventListener('close', () => {
      console.log('disconnected');
      clearInterval(listener)
      connect();
    });

    ws.addEventListener("message", event => {
      const data = JSON.parse(event.data)
      updateData(data)
    });

    ws.addEventListener('error', error => {
      console.error('Socket encountered error: ', error.message, 'Closing socket');
      clearInterval(listener)
      ws.close();
      // connect();
    })
  }

  // document.onkeydown = checkKey;

  useEffect(() => connect(), [])
  useEffect(() => {
    getConfig(0).then(item => getGame(item?.gameID).then(game => {
      setSmGame(game.smGameID)
    })).catch(error => console.log(error.message))
  }, [])

  const changePossession = (team) => {
    const currentTime = moment(timeRef.current, 'mm:ss').add((parseInt(period, 10) - 1) * 20, 'minutes').format('HH:mm:ss')
    addPossession({
      input: {
        smGame: parseInt(smGame, 10),
        time: currentTime,
        team
      }
    }).then(res => {
      if (!res.error) {
        setCurrentPossession(team)
      }
    })
  }

  const checkKey = (e) => {

    e = e || window.event;

    if (e.keyCode == '37') {
      changePossession('home')
    }
    else if (e.keyCode == '39') {
      changePossession('visitor')
    }

  }

  useEffect(() => {
    const check = e => checkKey(e)
    document.addEventListener('keydown', check)

    return () => document.removeEventListener('keydown', check)
  }, [])

  return (
      <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography variant={"body"} component={'div'} textAlign={"center"}>Spiel ID: {smGame}</Typography>
              <Typography variant={"h1"} textAlign={"center"}>{time}</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField label={'Period'} sx={{textAlign: 'center'}} fullWidth value={period} onChange={e => setPeriod(e.target.value)} />
            </Grid>
            <Grid item xs={5}>
              <Button
                fullWidth
                variant={currentPossession === 'home' ? "contained" : "outlined"}
                onClick={() => changePossession('home')}
                sx={{ height: 100}}
              >
                Heim
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Button
                fullWidth
                variant={currentPossession === 'visitor' ? "contained" : "outlined"}
                onClick={() => changePossession('visitor')}
                sx={{ height: 100}}
              >
                Gast
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}

Possession.propTypes = {}

export default Possession;
