import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  List,
  TextField,
  FormControl,
  FormLabel
} from "@mui/material";
import ListItemButton from '@mui/material/ListItemButton';
import {useIndexedDB} from "react-indexed-db";
import BootstrapDialogTitle from "../../components/BootstrapDialogTitle";
import GameSelector from "../gameSelector";
import CsvDownloader from 'react-csv-downloader';

const MainMenu = ({open, close, setAppGameID}) => {
  const { getAll: getAllShots } = useIndexedDB('shots');
  const { add, getByID, getAll, update } = useIndexedDB('games');
  const { getByID: getConfig, update: updateConfig } = useIndexedDB('config');
  const [allGames, setAllGames] = useState([]);
  const [editGameID, setEditGame] = useState(null);
  const [tmpHome, setTmpHome] = useState('');
  const [tmpVisitor, setTmpVisitor] = useState('');
  const [autosafe, setAutosafe] = useState(localStorage.getItem('autosafe') || false);
  const [smGameID, setSMGameID] = useState('');

  const addGame = () => {
    add({}).then(id => editGame(id))
  }

  useEffect(() => {
    setAutosafe(localStorage.getItem('autosafe') === 'true')
  }, [open])

  const toggleAutoSafe = () => {
    localStorage.setItem('autosafe', !autosafe)
    setAutosafe(!autosafe)
  }

  const editGame = id => {
    updateConfig({
      id: 0,
      gameID: id
    }).then(() => {
      setAppGameID(id)
      updateGame(id)
    })
  }

  const updateGame = id => {
    getByID(id).then(item => {
      if (item) {
        setEditGame(id)
        setTmpHome(item.home || '')
        setTmpVisitor(item.visitor || '')
        setSMGameID(item.smGameID || '')
      }
    })
  }

  const saveGame = () => {
    getByID(editGameID).then(item =>
      update({
        ...item,
        home: tmpHome,
        visitor: tmpVisitor,
        smGameID: smGameID
      }).then(() => close())
    )
  }

  useEffect(() => {
    getConfig(0).then(item => {
      if (item) {
        setEditGame(item?.gameID || null)
      }
    }).catch(error => {})
  }, [])

  useEffect(() => {
    getAll().then(items => {
      if (JSON.stringify(allGames) !== JSON.stringify(items)) {
        setAllGames(items)
      }
    })
  }, [addGame])

  const csvData = () => {
    return getAllShots().then(items => {
      const list = []
      items.forEach(item => {
        if (item.gameID === editGameID) {
          list.push(item)
        }
      })

      return list;
    })
  }

  return (
    <Dialog fullWidth maxWidth={'lg'} open={open} onClose={close}>
      <BootstrapDialogTitle onClose={close}>Hauptmenü</BootstrapDialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button variant={"contained"} onClick={addGame} fullWidth>Neues Spiel</Button>
            <List>
              {allGames.map(game => (
                <ListItemButton key={game.id} selected={editGameID === game.id} onClick={() => editGame(game.id)}>
                  {game.home} vs. {game.visitor}
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs={8}>
            {editGameID && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth value={tmpHome} onChange={e => setTmpHome(e.target.value)} label={'Heim'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth value={tmpVisitor} onChange={e => setTmpVisitor(e.target.value)} label={'Gast'} />
                </Grid>
                <Grid item xs={12}>
                  <GameSelector gameID={editGameID} updateTeams={updateGame} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth value={smGameID} onChange={e => {
                    setSMGameID(e.target.value)
                    localStorage.setItem('smGameID', e.target.value)
                  }} label={'SpielID Saisonmanager'} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <FormControlLabel
                      control={<Checkbox color="primary" checked={autosafe} onClick={toggleAutoSafe} />}
                      label="Schüsse automatisch bestätigen"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant={"contained"} onClick={saveGame}>Speichern</Button>
                </Grid>
                <Grid item xs={12}>
                  <CsvDownloader datas={csvData} filename={'fasd'}>
                    <Button fullWidth variant={"contained"}>Download</Button>
                  </CsvDownloader>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          const req = indexedDB.deleteDatabase('Shots')
          req.onsuccess = window.location.reload();
        }}>APP zurücksetzen</Button>
      </DialogActions>
    </Dialog>
  );
}

MainMenu.propTypes = {}

export default MainMenu;
