import React, {useCallback, useEffect, useRef, useState} from 'react';

import Court from "./features/court";
import Layout from "./layout";
import {Box, Button, FormControl, Grid, MenuItem, Select, Typography} from "@mui/material";
import Shot from "./features/shot";
import {useIndexedDB} from 'react-indexed-db';
import {ArrowLeft, ArrowRight} from "@material-ui/icons";
import PeriodInput from "./components/PeriodInput";
import Summary from "./features/summary";
import MainMenu from "./features/menu";
import TeamMenu from "./features/teamMenu";
import Bugsnag from "@bugsnag/js";
import {useMutation, useQuery} from "urql";
import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';

// const addOnlineShotQuery = gql`
//   createShot
// `

const readOnlineShotQuery = gql`
  query ReadShots($gameID: Int!) {
   readShots(filter: {smGame: {eq: $gameID}}) {
     hashID
     smGame
     number
     period
     type
     team
     x
     y
   }
  }
`

const addOnlineShotMutation = gql`
  mutation CreateShot($input: CreateShotInput!) {
   createShot(input: $input) {
     hashID
   }
  }
`

const App = ({}) => {
  const [teamMenuDialogOpen, setTeamMenuDialogOpen] = useState(false);
  const [menuDialogOpen, setMenuDialogOpen] = useState(false);
  const [shotDialogOpen, setShotDialogOpen] = useState(false);
  const [mirror, setMirror] = useState(false)
  const [gameID, setGameID] = useState(null)
  const [smGame, setSmGame] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [homeName, setHomeName] = useState('Team 1')
  const [visitorName, setVisitorName] = useState('Team 2')
  const [period, setPeriod] = useState(1)
  const [shots, setShots] = useState([])
  const [editX, setEditX] = useState(null)
  const [editY, setEditY] = useState(null)
  const [clickCount, setClickCount] = useState(null)
  const [typePreset, setTypePreset] = useState(false)
  const [editRightSide, setEditRightSide] = useState(false)

  const { add, getAll, deleteRecord: deleteShot } = useIndexedDB('shots');
  const { getByID: getConfig } = useIndexedDB('config');
  const { getByID: getGame } = useIndexedDB('games');
  const clickCountRef = useRef(0)
  const shotsSynced = useRef(false)

  const [onlineShotsResult, executeOnlineShotsQuery] = useQuery({
    query: readOnlineShotQuery,
    pause: !smGame,
    variables: {
      gameID: smGame
    },
    requestPolicy: "network-only"
  })

  const [state, createShot] = useMutation(addOnlineShotMutation)

  const syncOnlineShots = useCallback(() => {
    if (gameID) {
      getGame(gameID).then(res => {
        if (res?.smGameID) {
          getAll().then(items => {
            items.forEach(item => {
              if (item.gameID === gameID) {
                deleteShot(item.id)
              }
            })
            setShots([])
          }).then(() => {
            shotsSynced.current = false
            executeOnlineShotsQuery()
          })
        }
      })
    }
  }, [getGame, gameID, deleteShot, onlineShotsResult])

  useEffect(() => {
    if (gameID) {
      getGame(gameID).then(res => {
        const gameShots = shots.filter(item => item.gameID === gameID)
        if (!shotsSynced.current && res?.smGameID && gameShots.length === 0 && onlineShotsResult.data) {
          (onlineShotsResult?.data?.readShots || []).forEach(shot => {
            add({
              hashID: shot.hashID,
              gameID: gameID,
              shotStatus: shot.type,
              period: shot.period,
              team: shot.team,
              playerNo: shot.number,
              x: shot.x,
              y: shot.y
            }).then(res => {
              console.log(res)
            })
          })
          shotsSynced.current = true
        }
      }).then(() => {
        getAll().then(r => {
          setShots(r)
        })
      })
    }
  }, [onlineShotsResult.data, shots, gameID])

  useEffect(() => syncOnlineShots(), [gameID])

  const handleClick = (e, x, y, isRightSide) => {
    if (!gameID) {
      return;
    }

    // console.log(event.detail);
    console.log(editX, editY, clickCountRef.current, clickCount, e.detail)

    if (e.datail > clickCountRef.current) {
      clickCountRef.current = e.detail
    }

    // setTimeout(() => {
    //   if (clickCountRef.current !== e.detail) {
    //     // return
    //   }
    //
    //   let type = 0
    //   switch (clickCountRef.current) {
    //     case 1: type = 1; break;
    //     case 2: type = 3; break;
    //     case 3: type = 2; break;
    //   }
    //
    //   setTypePreset(type)
      setShotDialogOpen(true)
    // }, 400)

    if (!editX && !editY) {
      console.log('clicked', x, y)
      setEditX(x)
      setEditY(y)
      setEditRightSide(isRightSide)
    }
  }

  const changeSites = () => {
    setMirror(prevState => !prevState)
  }

  const addShot = (shotStatus, playerNo) => {
    setShotDialogOpen(false)
    if (editX !== null && editY !== null) {
      const team = !mirror
        ? (editRightSide ? 'visitor' : 'home')
        : (!editRightSide ? 'visitor' : 'home');

      getGame(gameID).then(result => {
        console.log('online', result.onlinegameid)

        const id = uuidv4();
        add({
          hashID: id,
          gameID: gameID,
          shotStatus: shotStatus,
          period: period,
          team: team,
          playerNo: playerNo,
          x: editX,
          y: editY
        }).then(e => {
          console.log(e)
          createShot({
            input: {
              hashID: id,
              smGame: smGame,
              number: playerNo,
              type: shotStatus,
              period: period,
              team: team,
              x: editX,
              y: editY
            }
          }).then(res => {
            if (res.error) {
              deleteShot(e)
            }
            console.log(res)
          })
          console.log('Item added', e);
        })
      })
    }

    clickCountRef.current = 0
    setEditX(null)
    setEditY(null)
    setClickCount(0)
    setEditRightSide(null)
  }

  const clearDatabase = successAction => {
    getAll().then(items => {
      items.forEach(item => {
        if (item.gameID === gameID) {
          deleteShot(item.id)
        }
      })
      setShots([])
    }).then(() => {
      successAction()
    })
  }

  useEffect(() => {
    getAll().then(dbShots => {
      if (JSON.stringify(dbShots) != JSON.stringify(shots)) {
        setShots(dbShots);
      }
    });
  }, [addShot, getAll, shots]);

  useEffect(() => {
    console.log('fasd')
    getConfig(0).then(item => setGameID(item?.gameID)).catch(error => console.log(error.message))
  }, [menuDialogOpen, setMenuDialogOpen])

  useEffect(() => {
    const config = getConfig(0).then(config => {
      if (!config || !config.gameID) {
        setMenuDialogOpen(true)
      }
    }).catch(error => console.log(error.message));
  }, [])

  useEffect(() => {
    if (!gameID) {
      return;
    }
    const game = getGame(gameID).then(game => {
      setHomeName(game?.home || '')
      setVisitorName(game?.visitor || '')
      setSmGame(parseInt(game?.smGameID || '', 10))
    }).catch(error => console.log(error.message));
  }, [gameID, menuDialogOpen])


  return (
    <Layout
      smGame={smGame}
      gameID={gameID}
      shots={[...shots].reverse()}
      setShots={setShots}
      homeName={homeName}
      visitorName={visitorName}
      clearDatabase={clearDatabase}
      showInfo={showInfo}
      setShowInfo={setShowInfo}
      setMenuDialogOpen={setMenuDialogOpen}
      setTeamMenuDialogOpen={setTeamMenuDialogOpen}
    >
     <MainMenu
        open={menuDialogOpen}
        close={() => setMenuDialogOpen(prev => !prev)}
        setAppGameID={setGameID}
      />
      <TeamMenu
        open={teamMenuDialogOpen}
        close={() => setTeamMenuDialogOpen(prev => !prev)}
        gameID={gameID}
        shots={shots.filter(shot => shot.gameID === gameID)}
      />
      <Grid container style={{height: 'calc(100% - 70px)'}}>

        {shotDialogOpen && (
          <Shot
            open={shotDialogOpen}
            cancel={() => {
              clickCountRef.current = 0
              setClickCount(0)
              setShotDialogOpen(false)
            }}
            addShot={addShot}
            typePreset={typePreset}
          />
        )}

        <Grid item xs={5} paddingRight={'5%'} alignSelf={"center"}>
          <Typography align={"right"} variant={'body2'} fontSize={12} color={'#fff'}>Schüsse abgegeben von</Typography>
          <Typography align={"right"} variant={'h5'} fontSize={24} color={'#fff'}>{!mirror ? homeName : visitorName}</Typography>
        </Grid>
        <Grid item xs={2} paddingLeft={'10px'} paddingRight={'10px'} textAlign={"center"} alignSelf={"center"}>
          <Button onClick={changeSites} fullWidth sx={{margin: 'auto'}} variant={"contained"}><ArrowLeft/><ArrowRight/></Button>
        </Grid>
        <Grid item xs={5} paddingLeft={'5%'} alignSelf={"center"}>
          <Typography align={"left"} variant={'body2'} fontSize={12} color={'#fff'}>Schüsse abgegeben von</Typography>
          <Typography align={"left"} variant={'h5'} fontSize={24} color={'#fff'}>{!mirror ? visitorName : homeName}</Typography>
        </Grid>

        <Grid item xs={5} paddingRight={'5%'}>
          <Typography align={"right"} variant={'body1'} fontSize={12} color={'#fff'}>
            <Summary label={'Gesamt: '} shots={shots.filter(shot => shot.gameID === gameID)} team={!mirror ? 'home' : 'visitor'} period={period} />
          </Typography>
          <Typography align={"right"} variant={'body1'} fontSize={12} color={'#fff'}>
            <Summary label={'Aufs Tor: '} shots={shots.filter(shot => shot.gameID === gameID)} team={!mirror ? 'home' : 'visitor'} period={period} filter={['goal', 'onGoal']} showPercentage />
          </Typography>
          <Typography align={"right"} variant={'body1'} fontSize={12} color={'#fff'}>
            <Summary label={'Block Quote: '} shots={shots.filter(shot => shot.gameID === gameID)} team={!mirror ? 'home' : 'visitor'} period={period} blockquote showPercentage />
          </Typography>
        </Grid>
        <Grid item xs={2} paddingLeft={'10px'} paddingRight={'10px'} textAlign={"center"}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              onChange={e => setPeriod(e.target.value)}
              input={<PeriodInput />}
            >
              <MenuItem value={1}>1. Drittel</MenuItem>
              <MenuItem value={2}>2. Drittel</MenuItem>
              <MenuItem value={3}>3. Drittel</MenuItem>
              <MenuItem value={'v'}>Verlängerung</MenuItem>
            </Select>
          </FormControl>
          {/*<Button onClick={changeSites} fullWidth sx={{margin: 'auto'}} variant={"contained"}><ArrowLeft/><ArrowRight/></Button>*/}
        </Grid>
        <Grid item xs={5} paddingLeft={'5%'}>
          <Typography align={"left"} variant={'body1'} fontSize={12} color={'#fff'}>
            <Summary label={'Gesamt: '} shots={shots.filter(shot => shot.gameID === gameID)} team={mirror ? 'home' : 'visitor'} period={period} />
          </Typography>
          <Typography align={"left"} variant={'body1'} fontSize={12} color={'#fff'}>
            <Summary label={'Aufs Tor: '} shots={shots.filter(shot => shot.gameID === gameID)} team={mirror ? 'home' : 'visitor'} period={period} filter={['goal', 'onGoal']} showPercentage />
          </Typography>
          <Typography align={"left"} variant={'body1'} fontSize={12} color={'#fff'}>
            <Summary label={'Block Quote: '} shots={shots.filter(shot => shot.gameID === gameID)} team={mirror ? 'home' : 'visitor'} period={period} blockquote showPercentage />
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Court
            showInfo={showInfo}
            shots={shots.filter(shot => shot.gameID === gameID)}
            period={period}
            handleClick={handleClick}
            teamFilter={!mirror ? 'home' : 'visitor'}
          />
        </Grid>
        <Grid item xs={6}>
          <Court
            showInfo={showInfo}
            shots={shots.filter(shot => shot.gameID === gameID)}
            period={period}
            handleClick={handleClick}
            teamFilter={!mirror ? 'visitor' : 'home'}
            reverse
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

App.propTypes = {}

export default App;
