import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Checkbox, FormControl, IconButton, Paper, Tab, Toolbar} from "@mui/material";
import Tabs from '@mui/material/Tabs';

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Shot = ({ open, cancel, addShot, typePreset }) => {
  const [autosubmit, setAutosubmit] = useState(false);
  const [onGoal, setOnGoal] = useState(0);
  const [playerNo, setPlayerNo] = useState('');

  useEffect(() => {
    // setOnGoal(typePreset)
  }, [typePreset])

  useEffect(() => {
    if (localStorage.getItem('autosafe') === 'true'  && playerNo.toString().length >= 2) {
    console.log(localStorage.getItem('autosafe') === 'true')
      add()
    }
  }, [playerNo, localStorage])

  const handleClose = () => {
    cancel()
  };

  const getStatus = (index) => {
    switch (index) {
      case 0: return 'goal';
      case 1: return 'onGoal';
      case 2: return 'blocked';
      case 3: return 'missed';
    }
  }

  const add = e => {
    e?.preventDefault();
    addShot(getStatus(onGoal), parseInt(playerNo, 10))
    setPlayerNo('')
    cancel()
  }

  const typePlayerNo = (num) => {
    setPlayerNo(prev => prev.toString() + num.toString())
  }

  const typeBack = () => {
    setPlayerNo(prev => {
      const string = prev;
      string.slice(0, -1)
      return prev.slice(0, -1)
    })
  }

  return (
    <>
      <Dialog fullScreen maxWidth={"sm"} open={open} onClose={handleClose}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Neuen Schuss hinzufügen
            </Typography>
            <Button color="inherit" onClick={add}>
              Hinzufügen
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form onSubmit={add}>
            <Paper >
              <Tabs
                value={onGoal}
                onChange={(event, newValue) => {
                  event.preventDefault();
                  setOnGoal(newValue)
                }}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab sx={{flexGrow: 1}} label="Tor!" />
                <Tab sx={{flexGrow: 1}} label="Aufs Tor" />
                <Tab sx={{flexGrow: 1}} label="Geblockt" />
                <Tab sx={{flexGrow: 1}} label="Vorbei" />
              </Tabs>
            </Paper>
            <br/>
            {/*<FormControl >*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    value={playerNo}*/}
            {/*    onChange={e => setPlayerNo(e.target.value)}*/}
            {/*    inputRef={(input) => {*/}
            {/*      if(input != null) {*/}
            {/*        input.focus();*/}
            {/*      }*/}
            {/*    }}*/}
            {/*    type={"text"}*/}
            {/*    label={'Spielernummer'}*/}
            {/*    inputProps={{ inputMode: 'tel' }}*/}
            {/*  />*/}

            <Typography variant={'h1'} align={"center"}>{playerNo || '--'}</Typography>


            {/*</FormControl>*/}
          </form>
          <div className={'numpad-container'}>
            <div className={'numpad'}>
              <button onClick={() => typePlayerNo(1)}>1</button>
              <button onClick={() => typePlayerNo(2)}>2</button>
              <button onClick={() => typePlayerNo(3)}>3</button>
              <button onClick={() => typePlayerNo(4)}>4</button>
              <button onClick={() => typePlayerNo(5)}>5</button>
              <button onClick={() => typePlayerNo(6)}>6</button>
              <button onClick={() => typePlayerNo(7)}>7</button>
              <button onClick={() => typePlayerNo(8)}>8</button>
              <button onClick={() => typePlayerNo(9)}>9</button>
              <div>
                {/*<Checkbox checked={autosubmit} onChange={() => setAutosubmit(pre => !pre)} />*/}
              </div>
              <button onClick={() => typePlayerNo(0)}>0</button>
              <button onClick={typeBack}>Back</button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={add} variant={"contained"} color={"primary"}>Hinzufügen</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Shot;
