export const DBConfig = {
  name: 'Shots',
  version: 2,
  objectStoresMeta: [
    {
      store: 'shots',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'hashID', keypath: 'hashID', options: { unique: false } },
        { name: 'gameID', keypath: 'gameID', options: { unique: false } },
        { name: 'period', keypath: 'period', options: { unique: false } },
        { name: 'team', keypath: 'team', options: { unique: false } },
        { name: 'playerNo', keypath: 'playerNo', options: { unique: false } },
        { name: 'shotStatus', keypath: 'shotStatus', options: { unique: false } },
        { name: 'x', keypath: 'x', options: { unique: false } },
        { name: 'y', keypath: 'y', options: { unique: false } }
      ]
    },
    {
      store: 'config',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'gameID', keypath: 'gameID', options: { unique: false } },
        { name: 'isLocal', keypath: 'isLocal', options: { unique: false } },
      ]
    },
    {
      store: 'games',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'onlinegameid', keypath: 'onlinegameid', options: { unique: false } },
        { name: 'smGameID', keypath: 'smGameID', options: { unique: false } },
        { name: 'home', keypath: 'home', options: { unique: false } },
        { name: 'visitor', keypath: 'visitor', options: { unique: false } },
      ]
    },
    {
      store: 'player',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'team', keypath: 'team', options: { unique: false } },
        { name: 'gameID', keypath: 'gameID', options: { unique: false } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'number', keypath: 'number', options: { unique: false } },
      ]
    }
  ]
};
