import React from 'react';
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";

const DeleteLocal = ({closeAction, clearDatabase}) => {

  return (
    <Dialog open onClose={closeAction}>
      <DialogContent>
        Sollen die angelegten Schüssen gelöscht werden?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => clearDatabase(closeAction)}>Löschen</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteLocal.propTypes = {}

export default DeleteLocal;
