import React from 'react';

const Summary = ({ period, label, shots, team, filter, showPercentage, blockquote }) => {
  const totalTeam = shots.filter(shot => team === shot.team && shot.period === period).length
  const totalOnGoal = shots.filter(shot => team === shot.team && shot.period === period && ['goal', 'onGoal'].includes(shot.shotStatus)).length

  const sum = shots.filter(shot => team === shot.team && shot.period === period).filter(shot => {
    if (filter && !filter.includes(shot.shotStatus)) {
      return false;
    }

    return true;
  }).length;

  if (blockquote) {
    const blocked = shots.filter(shot => team === shot.team && shot.period === period).filter(shot => {
      return ['blocked'].includes(shot.shotStatus)
    }).length;

    return (
      <>
        {`${label || ''}${blocked}`}
        {showPercentage && ` (${Math.round(blocked / (blocked + totalOnGoal) * 100) || 0}%)`}
      </>
    )
  }

  return (
    <>
      {`${label || ''}${sum}`}
      {showPercentage && ` (${Math.round(sum / totalTeam * 100) || 0}%)`}
    </>
  )
};

Summary.propTypes = {}

export default Summary;
