import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from "@mui/material";
import Sections from "./sections";

const Court = ({ showInfo, period, shots, handleClick, reverse, teamFilter }) => {
  const { t } = useTranslation();
  const frame = useRef()
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [offsetWidth, setOffsetWidth] = useState(0)

  useEffect(() => {
    const setOffsets = e => setTimeout(() => {
      setOffsetHeight(frame.current?.offsetHeight)
      setOffsetWidth(frame.current?.offsetWidth)
    }, 1000);
    window.addEventListener('resize', setOffsets);
    window.addEventListener('DOMContentLoaded', setOffsets);
    setOffsets();
  }, [frame.current])

  const touchHandler = e => {
    if (showInfo) {
      return
    }

    let element = frame.current;

    let bounds = element.getBoundingClientRect();
    const offsetWidth = element.offsetWidth
    const offsetHeight = element.offsetHeight

    let x;
    let y;

    let clientX;
    let clientY;

    if (e.clientX) {
      clientX = e.clientX;
      clientY = e.clientY;
    } else {
      clientX = e.touches[0]?.clientX;
      clientY = e.touches[0]?.clientY;
    }

    if (!reverse) {
      x = Math.round((clientX - bounds.left) / offsetWidth * 1000000) / 10000;
      y = Math.round((clientY - bounds.top) / offsetHeight * 1000000) / 10000;
    } else {
      x = 100 - Math.round((clientX - bounds.left) / offsetWidth * 1000000) / 10000;
      y = 100 - Math.round((clientY - bounds.top) / offsetHeight * 1000000) / 10000;
    }

    handleClick(e, x, y, reverse);
  }

  return (
    <Box
      position={"relative"}
      zIndex={2}
      border={1}
      height={'75vh'}
      maxWidth={'80vh'}
      borderRadius={'10vh 0 0 10vh'}
      marginLeft={!reverse && 'auto'}
      marginRight={reverse && 'auto'}
      overflow={"hidden"}
      sx={{transform: reverse && 'rotate(180deg)', background: '#fff'}}
    >
      <Box className={'groundline'}></Box>
      {!showInfo && (
        <>
          <Box
            position={"absolute"}
            zIndex={1}
            top={'50%'}
            left={'15%'}
            width={'7%'}
            height={'15%'}
            border={1}
            sx={{transform: 'translateY(-50%)'}}
          />
          <Box
            position={"absolute"}
            zIndex={1}
            top={'50%'}
            left={'12%'}
            width={'3%'}
            height={'11%'}
            border={1}
            sx={{transform: 'translateY(-50%)', background: '#000'}}
          />
          <Box
            position={"absolute"}
            zIndex={1}
            top={'50%'}
            left={'12%'}
            width={'20%'}
            height={'28%'}
            border={1}

            sx={{transform: 'translateY(-50%)'}}
          />
        </>
      )}
      <Box
        position={"absolute"}
        top={'12%'}
        left={'12%'}
        width={'8px'}
        height={'8px'}
        borderRadius={'4px'}
        border={1}
        sx={{transform: 'translate(-50%, -50%)', background: '#000'}}
      />
      <Box
        position={"absolute"}
        bottom={'12%'}
        left={'12%'}
        width={'8px'}
        height={'8px'}
        borderRadius={'4px'}
        border={1}
        sx={{transform: 'translate(-50%, -50%)', background: '#000'}}
      />
      <Box
        position={'absolute'}
        width={'88%'}
        height={'100%'}
        marginLeft={'12%'}
        className={'court-cluster'}
        zIndex={3}
        onClick={touchHandler}
        // onTouchEnd={touchHandler}
        onTouchStart={touchHandler}
        ref={frame}
      >
        {!showInfo && shots.filter(shot => shot.team === teamFilter && shot.period === period).map(shot => (
          <Box
            fontFamily={'sans-serif'}
            key={shot.id}
            className={['shot', shot.shotStatus].join(' ')}
            top={`${Math.round( offsetHeight * shot.y / 100)}px`}
            left={`${Math.round( offsetWidth * shot.x / 100)}px`}
            sx={reverse && {transform: 'translate(-50%, -50%) rotate(180deg)'}}
          >
            <Box
              color={'black'}
              position={'absolute'}
              zIndex={99}
              right={'-7px'}
              top={'-7px'}
              fontSize={'9px'}
              sx={{
                background: '#fff',
                paddingLeft: '2px'
              }}
            >
              {Number.isInteger(shot.playerNo)
                ? shot.playerNo
                : ''
              }
            </Box>
            {/*{console.log('top', shot.x, frame.current?.offsetHeight, !reverse && `${Math.round( frame.current?.offsetHeight * shot.x / 100)}px`)}*/}
            {/*{console.log('bottom', shot.x, frame.current?.offsetHeight, reverse && `${Math.round( frame.current?.offsetHeight * shot.x / 100)}px`)}*/}
            {/*{console.log('left', shot.y, frame.current?.offsetWidth, !reverse && `${Math.round( frame.current?.offsetWidth * shot.y / 100)}px`)}*/}
            {/*{console.log('right', shot.y, frame.current?.offsetWidth, reverse && `${Math.round( frame.current?.offsetWidth * shot.y / 100)}px`)}*/}
          </Box>
        ))}

        <Sections shots={shots.filter(shot => shot.team === teamFilter && shot.period === period)} showInfo={showInfo} reverse={reverse} />
      </Box>

    </Box>
  );
};

Court.propTypes = {};

export default Court;
