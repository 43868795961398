import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'urql';
import gql from 'graphql-tag';
import moment from "moment";
import {Typography} from "@material-ui/core";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useIndexedDB} from "react-indexed-db";

const readGameDays = gql`
  query ReadSpieltages($minDate: String!){
    readSpieltages(filter: {date: {gte: $minDate}}) {
      date
      place
      games {
        id
        begin
        home {
          name
          logoSvgUrl
          logoUrl
        }
        visitor {
          name
          logoSvgUrl
          logoUrl
        }
      }
    }
  }
`

const GameSelector = ({gameID, updateTeams}) => {
  const [localOnlineGameID, setLocalOnlineGameID] = useState('')
  const [gamedays] = useQuery({
    query: readGameDays,
    variables: {
      minDate: moment().format('YYYY-MM-DD')
    }
  })
  const { add, getByID, update } = useIndexedDB('games');

  const updateOnlineConfig = (onlineGameID, home, visitor) => {
    update({
      id: gameID,
      home: home,
      visitor: visitor,
      onlinegameid: onlineGameID
    }).then(() => setLocalOnlineGameID(localOnlineGameID))
      .then(() => updateTeams(gameID))
  }

  useEffect(() => {
    getByID(gameID).then(item => {
      console.log(item)
      setLocalOnlineGameID(item.onlinegameid)
    }).then(() => updateTeams(gameID))
  }, [gameID])

  useEffect(() => {console.log('launched', localOnlineGameID, gameID)}, [])

  return (
    <>
      <FormControl variant={"outlined"} fullWidth>
        <InputLabel id="demo-simple-select-label">Spiel auswählen</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={localOnlineGameID}
        >
          {gamedays.data?.readSpieltages.map(spieltag => (
            spieltag.games?.map(game => (
              <MenuItem value={game.id} style={{display: 'block'}} onClick={() => updateOnlineConfig(game.id, game.home.name, game.visitor.name)}>
                {/*{console.log(game.home)}*/}
                <Typography component={'span'} style={{width: '100%'}} variant={"body1"}>{`${moment(spieltag.date, 'YYYY-MM-DD').format('DD.MM.YYYY')} - ${spieltag.place}`}</Typography>
                <br/>
                <Typography component={'span'} style={{width: '100%'}} variant={"body2"}>{`${game.home.name} vs. ${game.visitor.name}`}</Typography>
              </MenuItem>

            ))
          ))}
        </Select>
      </FormControl>
    </>
  )
}

GameSelector.propTypes = {}

export default GameSelector;
